<template>
  <div class="app-download-buttons">
    <template v-if="isMobileOrTablet">
      <a
        data-testid="store-banner-link"
        href="#"
        @click.prevent="() => downloadApp"
      >
        <img
          data-testid="store-banner-img"
          :alt="downloadButtonImage.alt"
          :class="btnSize"
          class="store-button"
          :src="downloadButtonImage.src"
          loading="lazy"
          :width="downloadButtonImage.width"
          :height="downloadButtonImage.height"
        >
      </a>
    </template>
    <template v-else>
      <a
        class="app-link"
        data-testid="itunes-store-link"
        @click.prevent="downloadApp({ platform: 'ios' })"
      >
        <img
          :alt="iTunesButtonImage.alt"
          :class="btnSize"
          class="store-button"
          :src="iTunesButtonImage.src"
          loading="lazy"
          :width="iTunesButtonImage.width"
          :height="iTunesButtonImage.height"
        >
      </a>
      <a
        class="app-link"
        data-testid="google-play-link"
        @click.prevent="downloadApp({ platform: 'android' })"
      >
        <img
          :alt="playStoreButtonImage.alt"
          class="store-button"
          :class="btnSize"
          :src="playStoreButtonImage.src"
          loading="lazy"
          :width="playStoreButtonImage.width"
          :height="playStoreButtonImage.height"
        >
      </a>
    </template>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})

const { isAndroid, isMobileOrTablet } = useDevice()
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()
const { downloadApp } = useDownloadApp()

const props = defineProps({
  btnSize: {
    type: String,
    default: 'small',
  },
})

const imgSize = computed(() => {
  switch (props.btnSize) {
    case 'large':
      return {
        width: '189',
        height: '56',
      }
    case 'medium':
      return {
        width: '162',
        height: '48',
      }
    default:
      return {
        width: '108',
        height: '32',
      }
  }
})

const downloadButtonImage = computed(() => {
  return isAndroid ? playStoreButtonImage.value : iTunesButtonImage.value
})

const playStoreButtonImage = computed(() => {
  return {
    src: `${runtimeConfig.public.staticAssetsURL}/app/download/badge-play-store_${locale.value}.svg`,
    alt: t('appDownloadBanner.googlePlayAction'),
    ...imgSize.value,
  }
})

const iTunesButtonImage = computed(() => {
  return {
    src: `${runtimeConfig.public.staticAssetsURL}/app/download/badge-app-store_${locale.value}.svg`,
    alt: t('appDownloadBanner.iTunesAction'),
    ...imgSize.value,
  }
})
</script>

<style lang="scss" scoped>
.app-download-buttons {
  display: flex;
  gap: 0.5rem;
}

.app-link {
  cursor: pointer;
  display: block;
}

.store-button {
  width: auto;
  display: block;

  &.medium {
    height: 3rem;
  }

  &.large {
    height: 3.5rem;
  }
}
</style>

<i18n src="~/locales/common/app-download-banner.json" lang="json" />
